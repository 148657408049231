.react-datepicker__month-container {
  float: none;
}

.calendar-input-wrapper {
  position: relative;
}
.calendar-input-wrapper .calendar-input {
  height: 100%;
  width: 110px;
  padding-left: 26px;
}

.calendar-input-wrapper:before {
  content: '';
  background: url(../images/calendar.svg);
  display: block;
  position: absolute;
  left: 8px;
  top: 9px;
  height: 12px;
  pointer-events: none;
  width: 12px;
  z-index: 30;
}
.react-datepicker-wrapper:first-child:after {
  @apply bg-gray-250;
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  right: 0;
  height: 16px;
  width: 1px;
  pointer-events: none;
  z-index: 30;
}

.calendar-input--left {
  border-right: 0;
}

.calendar-input-wrapper .calendar-input--right {
  border-left: 0;
  width: 140px;
  padding-right: 40px;
}

.calendar-input-overlay {
  box-shadow: rgb(0 0 0 / 20%) 0 5px 5px -3px, rgb(0 0 0 / 14%) 0 8px 10px 1px, rgb(0 0 0 / 12%) 0 3px 14px 2px;
  transition: opacity 326ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.calendar-input-overlay__hide {
  opacity: 0;
}

.calendar-input-overlay__show {
  opacity: 1;
}

.calendar-input--disabled {
  @apply cursor-not-allowed z-20 border-gray-250 bg-white;
  color: rgba(0, 0, 0, 0.25);
}
