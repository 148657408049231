.button-group {
  @apply flex;
}
.button-group__item {
  @apply z-0 relative border border-l-transparent ml-[-1px] border-gray-250 px-3 pb-2 pt-2 text-gray-700 flex items-center leading-none cursor-pointer hover:text-green focus:border-green;
}

.button-group__item_wrapper {
  @apply h-full;
}
.button-group__item_wrapper a,
.button-group__item button {
  @apply h-full pb-1.5 pt-2.5 p-2 flex items-center;
}
.button-group__item--no-padding {
  @apply p-0;
}
.button-group__item--first {
  @apply rounded-l border-l-gray-250 z-10;
}
.button-group__item:focus,
.button-group__item:focus-visible,
.button-group__item:focus-within {
  @apply outline-0;
  /*box-shadow: 0 0 0 2px rgb(33 150 83 / 20%);*/
  @apply !border-gray-250;
}

.button-group__item--last {
  @apply rounded-r;
}

.button-group__item--single {
  @apply rounded border-l-gray-250;
}

.button-group__item--active,
.button-group__item:focus,
.button-group__item:focus-visible,
.button-group__item:focus-within {
  @apply text-green border-l-gray-250 bg-[#F1FCF6];
}

.button-group__item--active {
  z-index: 21;
}

.button-group__item--disabled {
  @apply cursor-not-allowed z-20 border-gray-250 bg-white text-gray-300 hover:text-gray-300;
}
.button-group__item--active + .button-group__item--disabled:not(.button-group__item--single):not(.button-group__item--first) {
  @apply border-l-green;
}

.button-group__item--disabled,
.button-group__item--disabled span,
.button-group__item--disabled a {
  @apply pointer-events-none text-gray-300;
}
